
//slick-slider common
.slick-slider{
  .slick-slide{
    display: none;
    li{
      margin: 0;
      vertical-align: bottom;
      img{
        vertical-align: bottom;
      }
    }
    &:first-child{
      display: block;
    }
  }
  &.slick-initialized{
    .slick-slide{
      display: block;
    }
  }
}



//Slider used on the top page
.c-slide01{
  margin: 0;
  padding: 40px 0;
  background: $baseColor;
  .slick-slide{
    margin: 0 10px;
  }
}